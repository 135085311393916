"use client"
import Image from 'next/image';
import Link from 'next/link';

const BannerTools = () => {
   
    return (
        <div className='md:absolute bottom-0 left-0 right-0 p-4 z-[11] md:bg-[#02160B]/50 bg-[#02160B]'>
           <div className='grid grid-cols-2 md:grid-cols-4 gap-8 md-gap-0'>
                <div className='text-center border-r border-white'>
                    <Link href="mailto:visitharmonyestate@gmail.com" target="_blank" rel="noopener" className='block'>
                        <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/enquriy.svg' alt='enquriy' width='25' height='25' className='m-auto'/>
                        <p className='lucida_fax text-white text-sm'>Enquriy</p>
                    </Link>
                </div>
                <div className='text-center md:border-r border-white'>
                    <Link href="tel:+916364306015" target="_blank" rel="noopener" className='block'>
                        <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/phone.svg' alt='phone' width='25' height='25' className='m-auto'/>
                        <p className='lucida_fax text-white text-sm'>Phone</p>
                    </Link>
                </div>
                <div className='text-center border-r border-white'>
                    <Link href="https://wa.me/+916364306015" target="_blank" rel="noopener" className='block'>
                        <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/whatsup.svg' alt='whatsup' width='25' height='25' className='m-auto'/>
                        <p className='lucida_fax text-white text-sm'>whatsapp</p>
                    </Link>
                </div>
                <div className='text-center'>
                    <Link href="contact-us" target="_blank" rel="noopener" className='block'>
                        <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/brochure.svg' alt='Brochure' width='25' height='25' className='m-auto'/>
                        <p className='lucida_fax text-white text-sm'>Brochure</p>
                    </Link>
                </div>
           </div>
        </div>
    );
}

export default BannerTools;