"use client"
import { useEffect } from "react";
import Image from 'next/image';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdArrowOutward  } from "react-icons/md";
import Link from "next/link";

const HowToKnow = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    const PrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-prev sm:top-[-40px] top-[-50px] !transform-none !bottom-0 !left-auto !right-[100px] border rounded-full border-[#C98A43] w-10 h-10 hover:bg-[#C98A43] flex justify-center items-center">
                <MdKeyboardArrowLeft className="sm:text-[30px] text-[20px] text-[#C98A43]" />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-next sm:top-[-40px] top-[-50px] !transform-none !bottom-0 !left-auto !right-[40px] border rounded-full border-[#C98A43] w-10 h-10 hover:bg-[#C98A43]  flex justify-center items-center">
                <MdKeyboardArrowRight className="sm:text-[30px] text-[20px] text-[#C98A43] " />
            </div>
        );
    };

    var settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "25%",
        className: "center",
        centerMode: true,
        speed: 500,
        dots: false,
        arrows: true,
        pauseOnHover: true,
        lazyLoad: true,
        customNav: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
              }
            }
          ]
    };

    return (

        <div className="relative">
            <Slider {...settings} className="banner-silder">
                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/jeevadhari2.webp' alt='Jeevadhari sustainable agriculture plot at Visit Harmony' width='608' height='456' className='w-full h-auto'/>
                </div>
                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/jeevadhari1.webp' alt='Jeevadhari farm plot at Visit Harmony with natural surroundings' width='608' height='456' className='w-full h-auto'/>
                </div>
                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/jeevadhari3.webp' alt='bannrt' width='608' height='456' className='w-full h-auto'/>
                </div>

                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/get-jeevadhatri3.webp' alt='bannrt' width='608' height='456' className='w-full h-auto'/>
                </div>
                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/get-jeevadhatri1.webp' alt='bannrt' width='608' height='456' className='w-full h-auto'/>
                </div>
                <div className="lg:p-3 sm:p-2 p-1">
                    <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/get-jeevadhatri4.webp' alt='Jeevadhatri eco-friendly farm plot at Visit Harmony' width='608' height='456' className='w-full h-auto'/>
                </div>

            </Slider>
            <Link href='/projects' className="absolute bottom-[-20px] w-28 md:h-28 h-20 left-0 right-0 m-auto p-3 bg-[#9BA343] text-white text-center flex justify-center items-center">
                <span className="block">
                    <p className="text-xs mb-2">TAKE A TOUR</p>
                    <MdArrowOutward className="text-white text-2xl m-auto"/>
                </span>
            </Link>
        </div>
    );
}

export default HowToKnow;