"use client"
import { useEffect } from "react";
import Image from 'next/image';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export default function VisionMission () {
    const silder = [{
        id:1,
        img:'https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/vm1.webp',
        title:'Sustainable community',
        description: 'At Harmony Estate, our mission is rooted in sustainable farming practices that regenerate soil health and enhance biodiversity. By using natural farming methods, we align with the rhythms of nature, promoting eco-friendly solutions that minimize our environmental footprint. We are committed to creating ecosystems that thrive, ensuring the land remains productive and healthy for generations to come.'
    },{
        id:2,
        img:'https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/vm2.webp',
        title:'Empowering Communities',
        description: 'Empowering communities is at the heart of our approach. We believe in fostering a sense of shared responsibility among individuals, where everyone plays an active role in nurturing the land. Our co-farming model encourages collaboration, enabling people to reconnect with nature and take part in conscious agricultural practices. Through this, we cultivate not only the land but also a community driven by purpose and sustainability.'
    },{
        id:3,
        img:'https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/vm3.webp',
        title:'Restoring Ecosystems',
        description: 'Restoring ecosystems is central to our vision. We are dedicated to transforming barren and degraded lands into fertile spaces that support year-round food production. By revitalizing the soil and adopting regenerative farming techniques, we create self-sustaining ecosystems that benefit both the environment and the people living in harmony with it.'
    },{
        id:4,
        img:'https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/vm4.webp',
        title:'Eco-Friendly Operations',
        description: 'In everything we do, eco-friendly operations are a priority. Our infrastructure and daily management processes are designed with sustainability in mind, from using sustainable materials to integrating energy-efficient systems. We aim to minimize our ecological footprint while ensuring that our farms remain productive and efficient.'
    },{
        id:5,
        img:'https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/vm5.webp',
        title:'Technology-Driven Farm Management',
        description: 'Technology also plays a key role in our vision for the future. By incorporating advanced farm management systems, we streamline operations and provide real-time insights to our community members. These digital tools simplify farm ownership, offering seamless communication, monitoring, and updates that enhance the overall farming experience.'
    }]

    const PrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-prev top-auto !transform-none md:!bottom-[20px] !bottom-[-45px] !left-auto !right-[100px] border rounded-full border-[#C98A43] w-10 h-10 hover:bg-[#C98A43] flex justify-center items-center z-10">
                <MdKeyboardArrowLeft className="sm:text-[30px] text-[20px] text-[#C98A43]" />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-next top-auto !transform-none md:!bottom-[20px] !bottom-[-45px] !left-auto !right-[40px] border rounded-full border-[#C98A43] w-10 h-10 hover:bg-[#C98A43]  flex justify-center items-center">
                <MdKeyboardArrowRight className="sm:text-[30px] text-[20px] text-[#C98A43] " />
            </div>
        );
    };
    
    const settings = {
        className: "center",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        dots: false,
        arrows: true,
        pauseOnHover: true,
        lazyLoad: true,
        customNav: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };
    return (
        <div className="container py-6">
            <Slider {...settings} className="banner-silder vision-slider relative">
                {silder.map((item,index) => (
                    <div className="px-0" key={index}>
                        <div className="grid grid-cols-12 md:gap-2 gap-4">
                            <div className="md:col-span-6 col-span-12">
                                <Image src={item.img} alt='' width='734' height='802' className="w-full h-auto" />
                            </div>
                            <div className="md:col-span-6 col-span-12">
                                <p className="text-[#171717] font-bold lg:text-[36px] text-[22px] mb-6 leading-tight">{item.title}</p>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
                
            </Slider>
        </div>
    )
}