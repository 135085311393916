import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        blank: "",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        primary: "font-normal bg-[#C98A43] rounded-none text-[#ffffff] hover:bg-[#000000] w-[100%] text-[18px] inline-block w-auto min-w-[190px] min-h-[59px]",
        outlineprimary:
          "border rounded-none rounded-none border-[#121212] bg-transparent text-[#121212] w-[100%] hover:bg-[#121212] hover:text-[#ffffff] font-semibold border-2",
        transparent: "bg-transparent text-[#121212] w-[100%] hover:bg-[#121212] border border-[#121212]",
        accordionbtn: "bg-[#007DB8] text-secondary-foreground hover:bg-[#70bee3] rounded text-[#fff]",
        coursedetails:
          "border rounded-none rounded-none border-[#121212] bg-transparent text-[#121212] w-[100%] hover:bg-[#121212] hover:text-[#ffffff] font-semibold border-2 text-[16px]",
      },
      size: {
        default: "px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
