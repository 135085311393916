"use client"
import Image from 'next/image';
import Link from 'next/link';
import BannerTools from './banner-tools';
import { Button } from '@/components/ui/button';

const Banner2 = () => {
   
    return (
        <div className='relative'>
            <Image fetchPriority="high" src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/home-banner-new.webp' alt='Visit Harmony homepage banner showcasing sustainable living' width='1263' height='630' className='desk-view w-full h-[300px] md:h-[500px] lg:h-auto'/>
            <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/home-banner-mobile.webp' alt='Visit Harmony homepage banner showcasing sustainable living' width='500' height='500' className='w-full h-[300px] md:h-[500px] lg:h-auto mobile-view'/>
            <div className='absolute md:top-0 top-[-130px] md:top-[-70px] lg:top-[-20px] left-0 right-0 bottom-0 max-w-[800px] m-auto px-6 text-white text-center flex justify-center items-center'>
                <div>
                <p className='lg:text-[42px] md:text-[36px] sm:text-[28px] text-[18px] lucida_fax'>HARMONY ESTATE</p>
                <h1 className='lg:text-[50px] md:text-[36px] sm:text-[38px] text-[20px] mb-3 lucida_fax'>CONSCIOUS COMMUNITY</h1>
                <p className='text-[24px] hidden md:block'>At Harmony Estate, we cultivate a conscious community that thrives on sustainability. Our commitment to 100% natural practices ensures a harmonious relationship with nature.</p>
                <Button className='uppercase rounded-none bg-[#C98A43] min-w-[230px] !leading-[59px] tracking-[2px] md:text-[18px] text-[14px] md:mt-8 p-0'><Link href="/contact-us" className="block">Join us</Link></Button>
                </div>
            </div>
            <BannerTools />
        </div>
    );
}

export default Banner2;