"use client"
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { MdArrowOutward } from "react-icons/md";
import ApiFromClient from '@/lib/getFromClient';
import moment from "moment";

export default function FormBlog() {
    const [items, setItems] = useState<any>([]);
    const [load, setLoad] = useState<any>(true);

    useEffect(() => {
        try {
            setItems([])
            setLoad(true)
            let queryParams = `?pageSizeRequested=${2}&currentPage=${1}`
            ApiFromClient.get(`/blogs/getAllNewBlogsForSite${queryParams}`).then((response: any) => {
                setItems(response?.data);
                setLoad(false)
            });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    return (
        <section className="bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/blogs-bg.webp')] bg-no-repeat bg-cover bg-bottom lg:py-28 py-12">
            <div className="container">
                <div className="grid grid-cols-12 gap-4">
                    <div className="md:col-span-4 col-span-12">
                        <p className="lg:text-[68px] md:text-[48px] text-[38px] lucida_fax leading-tight">HARMONY ESTATE</p>
                        <h2 className="lucida_fax text-[#9BA343] lg:text-[104px] md:text-[80px] text-[56px] leading-tight">BLOG</h2>
                        <Link href='/blog' className="bg-[#C98A43] p-3 inline-block mt-4"><MdArrowOutward className="text-white text-2xl"/></Link>
                    </div>
                    <div className="md:col-span-8 col-span-12">
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
                        {items?.data?.map((i: any, index: number) => (
                            <div className="relative" key={index}>
                                <Image src={i.main_image} alt="blog" width='433' height='597' className="w-full object-cover h-full"/>
                                <div className="text-white absolute bottom-0 lg:p-6 p-4" >
                                    <p className="lg:text-[32px] text-[24px] leading-tight mb-2"><Link className="" href={`blog/${i?.slug}`}>{i?.title}</Link></p>
                                    <p className="leading-tight text-sm">{i.author_name} - {moment(i?.publish_date).format('MMMM Do YYYY')}</p>
                                </div>
                            </div>
                        ))}
                            {/* <div className="relative">
                                <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/blog1.webp' alt="blog" width='433' height='597' className="w-full object-cover h-full"/>
                                <div className="text-white absolute bottom-0 lg:p-6 p-4" >
                                    <p className="lg:text-[32px] text-[24px] leading-tight mb-2">Organic vs. Conventional Farming</p>
                                    <p className="leading-tight text-sm">Karthik Shetty - September 2024</p>
                                </div>
                            </div>
                            <div className="relative">
                                <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/blog2.webp' alt="blog" width='433' height='597' className="w-full object-cover h-full"/>
                                <div className="text-white absolute bottom-0 lg:p-6 p-4">
                                    <p className="lg:text-[32px] text-[24px] leading-tight mb-2">Harmony Estate’s Organic Practices</p>
                                    <p className="leading-tight text-sm">Karthik Shetty - September 2024</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}